<template>
	<div class="flow">
        <div class="mask"></div>
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c">
				<img
					class="img"
					src="../image/common/footer_flow1.png"
					alt=""
				/>
			</div>
			<span class="text">在线预约</span>
		</div>
		<img class="img1" src="../image/common/footer_right.png" alt="" />
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c">
				<img
					class="img"
					src="../image/common/footer_flow2.png"
					alt=""
				/>
			</div>
			<span class="text">到店体验</span>
		</div>
		<img class="img1" src="../image/common/footer_right.png" alt="" />
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c">
				<img
					class="img"
					src="../image/common/footer_flow3.png"
					alt=""
				/>
			</div>
			<span class="text">量房设计</span>
		</div>
		<img class="img1" src="../image/common/footer_right.png" alt="" />
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c">
				<img
					class="img"
					src="../image/common/footer_flow4.png"
					alt=""
				/>
			</div>
			<span class="text">签订合同</span>
		</div>
		<img class="img1 hiddle" src="../image/common/footer_right.png" alt="" />
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c ">
				<img
					class="img"
					src="../image/common/footer_flow5.png"
					alt=""
				/>
			</div>
			<span class="text">进场施工</span>
		</div>
		<img class="img1" src="../image/common/footer_right.png" alt="" />
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c">
				<img
					class="img"
					src="../image/common/footer_flow6.png"
					alt=""
				/>
			</div>
			<span class="text">节点验收</span>
		</div>
		<img class="img1" src="../image/common/footer_right.png" alt="" />
		<div @click="openDialog" class="flow-item f-y-s-c">
			<div class="icon f-x-c-c">
				<img
					class="img"
					src="../image/common/footer_flow7.png"
					alt=""
				/>
			</div>
			<span class="text">售后保障</span>
		</div>
        <DialogForm ref="refDialogForm" adUnitCode="VqjM"></DialogForm>
	</div>
</template>
<script>
export default {
	data() {
		return {}
	},
	mounted() {},
	methods: {
        openDialog(){
            this.$refs.refDialogForm.showDialog('plan')
        }
    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
	.flow {
        position: relative;
		width: 100%;
		height: 220px;
        background:url('../image/common/flow_bg2.png') no-repeat ;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 27px;
		box-sizing: border-box;
		flex-wrap: wrap;
        .mask{
            position: absolute;
            width: 100%;
            height: 100%;
            background: #43187b;
            opacity: 0.9;
            left: 0;
            top: 0;
        }
        .hiddle{
            display: none;
        }
		.flow-item {
            z-index: 9;
			margin-bottom: 18px;
          
			.icon {
				width: 64px;
				height: 56px;
				border-radius: 5px;
				border: 1px solid #ffffff;
				box-sizing: border-box;
				.img {
					width: 39px;
					height: 36px;
                    object-fit: cover;
				}
			}
			.text {
				margin-top: 7px;
				
				font-weight: 400;
				font-size: 11px;
				color: #ffffff;
				line-height: 11px;
			}
		}
		.img1:nth-child(8) {
			display: none;
		}
		.img1 {
            object-fit: cover;
            z-index: 9;
			width: 21px;
			height: 21px;
			margin-top: -38px;
		}
	}
}
@media screen and (min-width: 900px) {
	.flow {
        position: relative;
		width: 100%;
		height: 360px;
        background:url('../image/common/flow_bg1.jpg') no-repeat ;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 180px;
		box-sizing: border-box;
        .mask{
            position: absolute;
            width: 100%;
            height: 100%;
            background: #43187b;
            opacity: 0.9;
            left: 0;
            top: 0;
        }
		.flow-item {
            cursor: pointer;
            z-index: 9;
			.icon {
				width: 170px;
				height: 150px;
				border-radius: 9px;
				border: 1px solid #ffffff;
				box-sizing: border-box;
				.img {
					width: 105px;
					height: 96px;
                    object-fit: cover;
				}
			}
			.text {
				margin-top: 20px;
				
				font-weight: 400;
				font-size: 30px;
				color: #ffffff;
				line-height: 30px;
			}
		}
		.img1 {
            z-index: 9;
			width: 42px;
			height: 42px;
            object-fit: cover;
			margin: -40px 10px 0 10px;
		}
	}
}
</style>
