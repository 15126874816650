var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow"},[_c('div',{staticClass:"mask"}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(0),_c('span',{staticClass:"text"},[_vm._v("在线预约")])]),_c('img',{staticClass:"img1",attrs:{"src":require("../image/common/footer_right.png"),"alt":""}}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(1),_c('span',{staticClass:"text"},[_vm._v("到店体验")])]),_c('img',{staticClass:"img1",attrs:{"src":require("../image/common/footer_right.png"),"alt":""}}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(2),_c('span',{staticClass:"text"},[_vm._v("量房设计")])]),_c('img',{staticClass:"img1",attrs:{"src":require("../image/common/footer_right.png"),"alt":""}}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(3),_c('span',{staticClass:"text"},[_vm._v("签订合同")])]),_c('img',{staticClass:"img1 hiddle",attrs:{"src":require("../image/common/footer_right.png"),"alt":""}}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(4),_c('span',{staticClass:"text"},[_vm._v("进场施工")])]),_c('img',{staticClass:"img1",attrs:{"src":require("../image/common/footer_right.png"),"alt":""}}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(5),_c('span',{staticClass:"text"},[_vm._v("节点验收")])]),_c('img',{staticClass:"img1",attrs:{"src":require("../image/common/footer_right.png"),"alt":""}}),_c('div',{staticClass:"flow-item f-y-s-c",on:{"click":_vm.openDialog}},[_vm._m(6),_c('span',{staticClass:"text"},[_vm._v("售后保障")])]),_c('DialogForm',{ref:"refDialogForm",attrs:{"adUnitCode":"VqjM"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon f-x-c-c"},[_c('img',{staticClass:"img",attrs:{"src":require("../image/common/footer_flow7.png"),"alt":""}})])
}]

export { render, staticRenderFns }